// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
//

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function BodyLayout({ children }: Props) {
    const pathname = usePathname();

    const isHome = pathname === '/';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    ...(!isHome && {
                        pt: { xs: 8, md: 10 },
                    }),
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
